//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      dialogNotice: false,
    };
  },
  methods: {
    openDialog() {
      this.dialogNotice = true;
    },
  },
};
